import React, { useRef, useState } from "react";
import "./lobbyStyle.css";
import * as Components from "./LobbyComponent";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function LobbyScreen() {
  const [signIn, setSignIn] = React.useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const signInGhostRef = useRef(null);
  const [signUpDetails, setSignUpDetails] = useState({
    userName: "",
    userEmail: "",
    userPassword: "",
  });

  const handleKeyDown = (event) => {
    if(event.key === "Enter") {
      handleSignIn(event);
    }
  };

  const validateEmail = (mail) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(mail).toLowerCase());
  };

  // Function to handle navigation to room
  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      if (!validateEmail(email)) {
        window.alert("Error! Email should look like this yourId@yourMail.com");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/AMuser/login`,
        {
          emailId: email,
          password: password,
        }
      );
      if (!response.data.isError) {
        sessionStorage.setItem("UserID", response.data.results);
        sessionStorage.setItem("EmailID", email);
        navigate(`/dashboard`);
      } 
      else   window.alert(response.data.message);
    } catch (error) {
      console.log("Error signing in:", error.message);
      window.alert("Error signing in:", error.message);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      if (!validateEmail(signUpDetails.userEmail)) {
        window.alert("Error! Email should look like this yourId@yourMail.com");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/AMuser/signUp`,
        {
          name: signUpDetails.userName,
          emailId: signUpDetails.userEmail,
          password: signUpDetails.userPassword,
        }
      );
      if(!response.data.isError) {
        signInGhostRef.current.click();
        window.alert(`SignUp successful for ${signUpDetails.userName}!`)
        setSignUpDetails({
          userName: "",
          userEmail: "",
          userPassword: "",
        });
      }
      else   window.alert(response.data.message);
    } catch (err) {
      console.log("Error signing in:", err.message);
      window.alert("Error signing in:", err.message);
    }
  }

  return (
    <div className="lobby-screen">
      <Components.Container>
        <Components.SignUpContainer signinIn={signIn}>
          <Components.Form onSubmit={handleSignUp}>
            <Components.Title>Create Account</Components.Title>
            <Components.Input 
              type="text" 
              placeholder="Name" 
              value={signUpDetails.userName || ""}  
              onChange={(e) => setSignUpDetails({
                userName: e.target.value,
                userEmail: signUpDetails.userEmail,
                userPassword: signUpDetails.userPassword,
              })}
            />
            <Components.Input 
              type="email" 
              placeholder="Email" 
              value={signUpDetails.userEmail || ""}  
              onChange={(e) => setSignUpDetails({
                userName: signUpDetails.userName,
                userEmail: e.target.value,
                userPassword: signUpDetails.userPassword,
              })}
            />
            <Components.Input 
              type="password" 
              placeholder="Password" 
              value={signUpDetails.userPassword || ""}  
              onChange={(e) => setSignUpDetails({
                userName: signUpDetails.userName,
                userEmail:signUpDetails.userEmail,
                userPassword: e.target.value,
              })}
            />
            <Components.Button type="submit">Sign Up</Components.Button>
          </Components.Form>
        </Components.SignUpContainer>

        <Components.SignInContainer signinIn={signIn}>
          <Components.Form onSubmit={handleSignIn} onKeyDown={handleKeyDown}>
            <Components.Title>Sign in</Components.Title>
            <Components.Input
              type="email"
              placeholder="Email"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Components.Input
              type="password"
              placeholder="Password"
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Components.Anchor href="#">
              Forgot your password?
            </Components.Anchor>
            <Components.Button type="submit">Sign In</Components.Button>
          </Components.Form>
        </Components.SignInContainer>

        <Components.OverlayContainer signinIn={signIn}>
          <Components.Overlay signinIn={signIn}>
            <Components.LeftOverlayPanel signinIn={signIn}>
              <Components.Title>Welcome Back!</Components.Title>
              <Components.Paragraph>
                To keep connected with us please login with your personal info
              </Components.Paragraph>
              <Components.GhostButton ref={signInGhostRef} onClick={() => setSignIn(true)}>
                Sign In
              </Components.GhostButton>
            </Components.LeftOverlayPanel>

            <Components.RightOverlayPanel signinIn={signIn}>
              <Components.Title>Hello, Friend!</Components.Title>
              <Components.Paragraph>
                Enter your personal details and start your journey with us
              </Components.Paragraph>
              <Components.GhostButton onClick={() => setSignIn(false)}>
                Sign Up
              </Components.GhostButton>
            </Components.RightOverlayPanel>
          </Components.Overlay>
        </Components.OverlayContainer>
      </Components.Container>
    </div>
  );
}

export default LobbyScreen;
