import { useNavigate } from "react-router-dom";
import "./topbar.css";
import LogoutIcon from "@mui/icons-material/Logout";

export default function Topbar() {
  const navigate = useNavigate();
  const user = sessionStorage.getItem("EmailID");

  const handleLogout = () => {
    sessionStorage.removeItem("UserID");
    sessionStorage.removeItem("EmailID");
    navigate("/", { replace: true });
  };

  return (
    <div className="topbarContainer">
      <div className="topbarLeft">
        <span className="logo">App-Management</span>
      </div>
      <div onClick={handleLogout} className="topbarRight">
        <span className="userName">{ user }</span>
        <button className="logoutBtn">
          <LogoutIcon className="logoutIcon" />
        </button>
      </div>
    </div>
  );
}
